import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import "../../../styles/formCampaign.scss";
import { Container } from "react-bootstrap";
import { Form, Col, Row, Button, InputGroup } from "react-bootstrap";

import BaseForm from "../../shared/components/BaseForm";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../shared/shared.api";
import { getCreator } from "../../creator/creator.api";
import { postCampaign } from "../campaign.api";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

export default function FormCampaign() {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const [listCreator, setListCreator] = useState([]);

  useEffect(() => {
    dispatch(getCreator())
      .then(unwrapResult)
      .then((res) => {
        console.log(res);
        const dataopt = res.data.map((res) => ({
          label: res.name,
          value: res._id,
        }));
        setListCreator([{ label: "Pilih Creator", value: "" }, ...dataopt]);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error get creator",
        });
      });
  }, []);

  const originalForm = [
    {
      id: "campaing_type",
      type: "select",
      label: "Campaign Type",
      placeholder: "Select campaign type",
      opt: [
        {
          label: "Donation",
          value: "0",
        },
        {
          label: "Event",
          value: "1",
        },
        {
          label: "Donation & Event",
          value: "2",
        },
      ],
    },
    {
      id: "title",
      type: "text",
      label: "Event name",
      placeholder: "Insert event name",
    },
    {
      id: "description",
      type: "textarea",
      label: "About event",
      placeholder: "Insert about campaign",
    },
    {
      id: "creator",
      type: "select",
      label: "Creator",
      placeholder: "Select creator",
      opt: listCreator,
    },
    {
      id: "donation_amount",
      type: "number",
      label: "Amount Money",
      placeholder: "Insert amount money",
    },
    {
      id: "location",
      type: "text",
      label: "Event location",
      placeholder: "Insert event location",
    },
    {
      id: "participant",
      type: "text",
      label: "Number of participants",
      placeholder: "Insert number",
    },
    {
      id: ["start_date", "end_date"],
      type: "daterange",
      label: "Campaign Period",
    },
    {
      id: "status",
      type: "select",
      label: "Status",
      placeholder: "Select status",
      opt: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
    },
    {
      id: "campaign_images",
      type: "images", //multiple
      label: "Upload Creative",
    },
  ];

  const formik = useFormik({
    initialValues: {
      campaing_type: "0",
      title: "",
      description: "",
      creator: "",
      donation_amount: 0,
      location: "",
      participant: 0,
      start_date: "",
      end_date: "",
      status: "active",
      campaign_images: [],
    },
    validationSchema: Yup.object().shape({
      campaing_type: Yup.string().required(),
      title: Yup.string().required(),
      description: Yup.string().required(),
      creator: Yup.string().required(),
      donation_amount: Yup.number(),
      location: Yup.string(),
      participant: Yup.number(),
      start_date: Yup.string().required(),
      end_date: Yup.string().required(),
      status: Yup.string().required(),
      campaign_images: Yup.array().min(1).required(),
    }),
    onSubmit: (values) => {
      if (values?.campaing_type === "0") {
        // donation
        delete values.location;
        delete values.participant;
      }

      if (values?.campaing_type === "1") {
        // event
        delete values.donation_amount;
      }

      values.status = values.status === "active" ? true : false;
      values.campaing_type = parseInt(values.campaing_type); /// DONT EDIT THE TYPO "CAMPAING" <<<

      dispatch(postCampaign(values))
        .then(unwrapResult)
        .then((res) => {
          console.log(res);
          Swal.fire({
            // position: 'top-end',
            icon: "success",
            title: "Your data has been saved",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            navigate("/admin/campaign");
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            // position: 'top-end',
            icon: "error",
            title: "Your data cannot saved",
          });
        });
    },
  });

  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    formik;

  const [dataForm, setDataForm] = useState([]);

  useEffect(() => {
    if (values?.campaing_type === "0") {
      // donation
      const newData = originalForm.filter(
        (val) => val.id !== "location" && val.id !== "participant"
      );
      setDataForm(newData);
    }

    if (values?.campaing_type === "1") {
      // event
      const newData = originalForm.filter(
        (val) => val.id !== "donation_amount"
      );
      setDataForm(newData);
    }

    if (values?.campaing_type === "2") {
      // all
      setDataForm(originalForm);
    }
  }, [values?.campaing_type, listCreator]);

  return (
    <Container fluid className="form-campaign">
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      <div className="top-row">
        <div className="title-container">
          <Link to="/admin/campaign">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Link>
          <span className="title">Create Campaign</span>
        </div>

        <BaseForm
          formField={dataForm}
          touched={touched}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          errors={errors}
          setFieldValue={setFieldValue}
          values={values}
          hiddenButton={true}
        />

        <Button className="mt-5" type="button" onClick={handleSubmit}>
          Submit form
        </Button>
      </div>
    </Container>
  );
}

import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../../styles/home.scss";
import { Button } from "antd";
import { rupiahFormat } from "../../utils/Helper";

import { getUser, getDataDashboard } from "./home.api";
import { increment, incrementByAmount } from "./home.reducer";

export default function Home() {
  const dispatch = useDispatch();
  const { dashBoardData } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getDataDashboard());
  }, []);

  return (
    <Container className="dashboard">
      <span className="title">Dashboard</span>
      <div className="cardBox">
        <div className="card">
          <div>
            <div className="numbers">
              {dashBoardData?.data?.active_campaign}
            </div>
            <div className="cardName">Active Campaign</div>
          </div>
          <div className="iconBx">
            <ion-icon name="megaphone"></ion-icon>
          </div>
        </div>
        <div className="card">
          <div>
            <div className="numbers">{dashBoardData?.data?.total_user}</div>
            <div className="cardName">Total Users</div>
          </div>
          <div className="iconBx">
            <ion-icon name="person"></ion-icon>
          </div>
        </div>
        <div className="card">
          <div>
            <div className="numbers">{dashBoardData?.data?.total_donator}</div>
            <div className="cardName">Total Contributor</div>
          </div>
          <div className="iconBx">
            <ion-icon name="hand-right"></ion-icon>
          </div>
        </div>
      </div>
      <div className="cardBoxContribution">
        <div className="cardContribution">
          <div>
            <div className="numbers">
              {rupiahFormat(dashBoardData?.data?.total_contribution)}
            </div>
            <div className="cardName">Total Contribution</div>
          </div>
          <div className="iconBx">
            <ion-icon name="happy-outline"></ion-icon>
          </div>
        </div>
      </div>
    </Container>
  );
}

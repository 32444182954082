import { createSlice } from "@reduxjs/toolkit";
import { getCategory, postMultipleImages } from "./shared.api";

const initialState = {
    loading: false,
    listCategory: [],
};

export const sharedSlice = createSlice({
    name: "shared",
    initialState,
    reducers: {},
    helperImages: [],
    extraReducers: {
        [getCategory.pending]: (state, action) => {
            state.loading = true;
        },
        [getCategory.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.listCategory = payload?.data;
        },
        [getCategory.rejected]: (state, action) => {
            state.loading = false;
        },
        [postMultipleImages.pending]: (state, action) => {
            state.loading = true;
        },
        [postMultipleImages.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.helperImages = payload?.data;
        },
        [postMultipleImages.rejected]: (state, action) => {
            state.loading = false;
        },

    },

});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = sharedSlice.actions;

export default sharedSlice.reducer;

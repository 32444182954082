import { createSlice } from "@reduxjs/toolkit";
import { postRegister, postLogin } from "./auth.api";

const getToken = async () => localStorage.getItem("");

const initialState = {
  value: 0,
  loading: false,
  register: null,
  login: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [postRegister.pending]: (state, action) => {
      state.loading = true;
    },
    [postRegister.fulfilled]: (state, { payload }) => {
      console.log(payload, "PYLDREG");
      state.loading = false;
      state.register = payload;
    },
    [postRegister.rejected]: (state, action) => {
      state.loading = false;
    },
    [postLogin.pending](state, action) {
      state.loading = true;
    },
    [postLogin.fulfilled](state, { payload }) {
      console.log(payload, "PAYLOAD");
      state.loading = false;
      state.login = payload;
      localStorage.setItem("token", payload.data.token);
    },
    [postLogin.rejected](state, action) {
      state.loading = false;
    },
    // [logout.pending](state, action) {
    //   state.loading = true;
    // },
    // [logout.fulfilled](state, {payload}) {
    //   state.loading = false;
    //   state.login = null;
    // },
    // [logout.rejected](state, action) {
    //   state.loading = false;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = authSlice.actions;

export default authSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Table, Input } from "antd";
import moment from "moment";
import { rupiahFormat } from "../../utils/Helper";
import "../../styles/article.scss";
import { deleteArticle, getArticle } from "./article.api";
import Swal from "sweetalert2";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

const { Search } = Input;

export default function Article() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { listArticle, loading } = useSelector((state) => state.article);
  const [create, setCreate] = useState(false);
  const [mergeUser, setMergeUser] = useState([]);
  const [Filter, setFilter] = useState({
    email: "",
  });

  useEffect(() => {
    dispatch(getArticle());
  }, []);

  const openCreate = () => {
    setCreate(!create);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteArticle(id))
          .then(unwrapResult)
          .then((res) => {
            Swal.fire("Deleted!", `Your file has been deleted `, "success");
            dispatch(getArticle());
          })
          .catch((err) => {
            Swal.fire("Failed!", `Your file failed to delete `, "error");
          });
      }
    });
  };

  console.log(listArticle, "tes");

  return (
    <Container fluid className="article">
      <span className="title">Article</span>
      {/* <div className="button" onClick={() => openCreate()}>
            <div className="cardName">Create campaign</div>
            <span className="iconBx">
              <ion-icon name="add-circle"></ion-icon>
            </span>
        </div> */}
      <div className="cardBox">
        <div className="card">
          <div>
            <div className="numbers">{listArticle.length}</div>
            <div className="cardName">Articles</div>
          </div>
          <div className="iconBx">
            <ion-icon name="megaphone"></ion-icon>
          </div>
        </div>
      </div>

      <div className="button">
        <Link to="/admin/article/form">
          <h2 className="cardName">Create article</h2>
          <span className="iconBx">
            <ion-icon name="add-circle"></ion-icon>
          </span>
        </Link>
      </div>
      <Table
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            fixed: "left",
            width: "fit-content",
          },
          {
            title: "Author",
            dataIndex: "author",
            key: "author",
          },
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (v) => moment(v).format("DD MMM YYYY"),
          },
          {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: "fit-content",
            render: (_, obj) => (
              <div className="action-buttons">
                <button onClick={() => handleDelete(obj._id)}>
                  <ion-icon name="trash"></ion-icon>
                </button>
                <Link to={`/admin/article/${obj._id}`}>
                  <button>
                    <ion-icon name="create"></ion-icon>
                  </button>
                </Link>
              </div>
            ),
          },
        ]}
        dataSource={listArticle}
        size="middle"
        loading={loading}
        pagination={{
          size: 10,
          showSizeChanger: true,
          // onShowSizeChange: (e, v) => console.log(e, v),
          showTotal: (total, range) => (
            <span style={{ left: 0, position: "absolute" }}>
              Showing {range[0]} to {range[1]} of {total} results
            </span>
          ),
        }}
        scroll={{
          x: "100%",
          // y: 300,
        }}
      />
    </Container>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postResetPass = createAsyncThunk(
  "forgotpass/postResetPass",
  async ({ values, token }, { rejectWithValue }) => {
    console.log(values, "data di camp api");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/auth/reset-password`,
        values,
        {
          headers: {
            // "Content-Type": "multipart/form-data", use it if form data
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

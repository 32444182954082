import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/user.scss";
import { getUser } from "./user.api";
import { Table, Input } from "antd";
import moment from "moment";
import { rupiahFormat } from "../../utils/Helper";

const { Search } = Input;

const columns = [
    // {
    //     title: "No",
    //     dataIndex: "number",
    //     key: "number",
    //     // fixed: "left",
    //     width: 100,
    // },
    {
        title: "Full Name",
        dataIndex: "username",
        key: "username",
        fixed: "left",
        width: "fit-content",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "User ID",
        dataIndex: "_id",
        key: "_id",
    },
    {
        title: "Join Date",
        dataIndex: "date",
        key: "date",
        render: (v) => moment(v).format("DD MMM YYYY"),
    },
    {
        title: "Status",
        dataIndex: "confirmed",
        key: "confirmed",
        render: (v) =>
            v ? (
                <span className="text-success">Active</span>
            ) : (
                <span className="text-danger">Inactive</span>
            ),
        sorter: (a, b) => a.confirmed - b.confirmed, //true false  values
    },
    {
        title: "Total Donation",
        dataIndex: "donation",
        key: "confirmed",
        render: (v) => rupiahFormat(v),
        // defaultSortOrder: 'descend',
        sorter: (a, b) => a.donation - b.donation,
    },
    // {
    //     title: "Action",
    //     key: "operation",
    //     fixed: "right",
    //     width: 'fit-content',
    //     render: () => <div className="action-buttons">
    //         <button>
    //             <ion-icon name="trash"></ion-icon>
    //         </button>
    //         <button>
    //             <ion-icon name="create"></ion-icon>
    //         </button>
    //         <button>
    //             <ion-icon name="information-circle"></ion-icon>
    //         </button>
    //     </div>,
    // },
];

export default function Campaign() {
    const dispatch = useDispatch();
    const { listUser, listContributor, active, inactive, loading } = useSelector(
        (state) => state.user
    );
    const [mergeUser, setMergeUser] = useState([]);

    const [Filter, setFilter] = useState({
        email: "",
    });

    useEffect(() => {
        dispatch(getUser(Filter));
    }, [Filter]);


    const onSearch = (value) => setFilter({ ...Filter, email: value });

    return (
        <Container fluid className="user">
            <span className="title">User</span>
            <div className="cardBox">
                <div className="card">
                    <div>
                        <div className="numbers">{active}</div>
                        <div className="cardName">Active User</div>
                    </div>
                    <div className="iconBx">
                        <ion-icon name="megaphone"></ion-icon>
                    </div>
                </div>
                <div className="card">
                    <div>
                        <div className="numbers">{listUser.length}</div>
                        <div className="cardName">Total Users</div>
                    </div>
                    <div className="iconBx">
                        <ion-icon name="person"></ion-icon>
                    </div>
                </div>
            </div>

            <Search
                placeholder="Search email"
                allowClear
                onSearch={onSearch}
                size="large"
                style={{
                    maxWidth: "300px",
                }}
                className="mb-4"
            />
            <Table
                columns={columns}
                dataSource={listUser}
                size="middle"
                loading={loading}
                pagination={{
                    size: 10,
                    showSizeChanger: true,
                    // onShowSizeChange: (e, v) => console.log(e, v),
                    showTotal: (total, range) => (
                        <span style={{ left: 0, position: "absolute" }}>
                            Showing {range[0]} to {range[1]} of {total} results
                        </span>
                    ),
                }}
                scroll={{
                    x: "100%",
                    // y: 300,
                }}
            />
        </Container>
    );
}

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "../../../styles/formCampaign.scss";
import BaseForm from "../../shared/components/BaseForm";
import { Container, Button } from "react-bootstrap";

// import db from "../../../firebaseInit";
// import { getDoc } from "@firebase/firestore";
// import moment from "moment";
import { postArticle } from "../article.api";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

export default function FormArticle() {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title: "",
      author: "",
      content: "",
      banner: [],
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      author: Yup.string().required(),
      content: Yup.string().required(),
      banner: Yup.array().min(1).required(),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("author", values.author);
      formData.append("title", values.title);
      formData.append("content", values.content);

      values.banner.forEach((file) => {
        formData.append("banner", file.file);
      });

      dispatch(postArticle(formData))
        .then(unwrapResult)
        .then((res) => {
          // console.log(res);
          Swal.fire({
            icon: "success",
            title: "Your data has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          Swal.fire({
            // position: 'top-end',
            icon: "error",
            title: "Your data cannot saved",
          });
          console.log(err.response);
        });
    },
  });

  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    formik;

  const formField = [
    {
      id: "author",
      type: "text",
      label: "Author",
      placeholder: "Insert author",
    },
    {
      id: "title",
      type: "text",
      label: "Article title",
      placeholder: "Insert article title",
    },
    {
      id: "banner",
      type: "image",
      label: "Upload banner",
    },
    {
      id: "content",
      type: "quill",
      label: "Article Content",
    },
  ];

  // const messageRef = db.collection("NOTIF_DATA");

  // useEffect(() => {
  //   getNotif();
  //   // console.log(notifData)
  // }, []);

  // const getNotif = async (id) => {
  //   messageRef
  //     // .orderBy("updated", "desc")
  //     // .where("partnerId", "array-contains-any", [id])
  //     .onSnapshot((res) => {
  //       let data = res.docs.map((doc) => {
  //         return {
  //           ...doc.data(),
  //           time_string: moment(doc.data().date.toDate()).format(
  //             "DD-MM-YYYY hh:mm"
  //           ),
  //         };
  //       });
  //       // setUser(data)
  //       console.table(data);
  //     });
  // };

  // const handleSendNotif = () => {
  //   const batch = db.batch();
  //   const receiver = ["kemal@gmail.com", "gala@gmail.com"];

  //   const data = {
  //     type: "campaignUpdate", // campaignUpdate //notifeSuccss etc
  //     title: "ada update artile",
  //     desc: "ini desc",
  //     date: new Date(),
  //     isRead: false,
  //   };

  //   receiver.forEach((email) => {
  //     var docRef = messageRef.doc(uuidv4()); //automatically generate unique id
  //     const dataForSend = {
  //       email: email,
  //       ...data,
  //     };
  //     batch.set(docRef, dataForSend);
  //   });

  //   return batch.commit();
  //   // getNotif()s
  // };

  return (
    <Container fluid className="form-campaign">
      <pre>{JSON.stringify(values, null, 2)}</pre>
      <div className="top-row">
        <div className="title-container">
          <Link to="/admin/article">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Link>
          <span className="title">Create Article</span>
        </div>
      </div>
      <BaseForm
        formField={formField}
        touched={touched}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={errors}
        setFieldValue={setFieldValue}
        values={values}
      />
      <Button className="mt-5" type="button" onClick={handleSubmit}>
        Submit article
      </Button>
      {/* 
      <button type="button" onClick={handleSendNotif}>
        send notif
      </button> */}
    </Container>
  );
}

// "name_product": "bridgstone",
//     "product_code": "xx-xx-xx",
//     "brand_id": "73a45012-36d8-4f37-93db-5b66e3e9f97e",
//     "product_category_id": "5a1d9597-2e56-4681-a933-77fcada09cb4",
//     "type_product_id": "ban racing",
//     "created_by" : "xx-xx-xx"

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../utils/config.json";

export const getUser = createAsyncThunk("user/getUser", async (data, { rejectWithValue }) => {
    console.log(data, "data");
    try {
        const response = await axios.get(`${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/user`, {
            params: data,
            headers: {
                Authorization: localStorage.getItem('token')
            }
        });
        console.log(response.data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response);
    }
}
);

import { createSlice } from "@reduxjs/toolkit";
import { getTransaction, getCampaignDonation } from "./transaction.api";

const initialState = {
  loading: false,
  listTransaction: [],
  listCampaignDonation: [],
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: {
    [getTransaction.pending]: (state, action) => {
      state.loading = true;
    },
    [getTransaction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      console.log(payload, "donations");
      state.listTransaction = payload?.data;
    },
    [getTransaction.rejected]: (state, action) => {
      state.loading = false;
    },
    [getTransaction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listTransaction = payload?.data;
    },
    [getTransaction.rejected]: (state, action) => {
      state.loading = false;
    },
    [getCampaignDonation.pending]: (state, action) => {
      state.loading = true;
    },
    [getCampaignDonation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listCampaignDonation = payload?.data;
    },
    [getCampaignDonation.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } =
  transactionSlice.actions;

export default transactionSlice.reducer;

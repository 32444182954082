import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../utils/config.json";

export const getCreator = createAsyncThunk(
  "creator/getCreator",
  async (params, { rejectWithValue }) => {
    console.log(params, "params");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/creator`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const postCreator = createAsyncThunk(
  "creator/postCreator",
  async (data, { rejectWithValue }) => {
    console.log(data, "data di postcreator");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/creator`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const deleteCreator = createAsyncThunk(
  "creator/deleteCreator",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/creator/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getCreatorDetail = createAsyncThunk(
  "creator/getCreatorDetail",
  async (id, { rejectWithValue }) => {
    console.log(id, "data");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/creator/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const patchCreator = createAsyncThunk(
  "creator/patchCreator",
  async (data, { rejectWithValue }) => {
    console.log(data, "data di camp api");
    const form = data.formData;
    try {
      console.log(data, "data di api");
      // let accToken = await getToken();
      const response = await axios.patch(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/creator/${data.id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

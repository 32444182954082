import React, { useEffect, useState } from "react";

import Logo from "../../assets/BeriMakna_Logo.png";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import BaseForm from "../shared/components/BaseForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { postResetPass } from "./forgotpass.api";
import Swal from "sweetalert2";

function Verification() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [nav, setNav] = useState(false);
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();

  const { token } = useParams();

  useEffect(() => {
    console.log(token, "TOKEN");
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      ConfirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      ConfirmPassword: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    }),
    onSubmit: (values) => {
      const data = {
        values,
        token,
      };
      dispatch(postResetPass(data))
        .then(unwrapResult)
        .then((res) => {
          window.location.replace("/resetpasswordsuccess");
          console.log(res, "RES");
        })
        .catch((err) => {
          console.log(err);
          window.location.replace("/resetpasswordfailed");
          console.log(err, "ERROR");
        });
      console.log(values, "VALUS");
    },
  });

  const formField = [
    {
      id: "password",
      type: "text",
      label: "New Password",
      placeholder: "Insert new password",
      style: "#fdfdfd",
    },
    {
      id: "ConfirmPassword",
      type: "text",
      label: "Confirm Password",
      placeholder: "Insert confirm password",
      style: "#fdfdfd",
    },
  ];

  const {
    values,
    handleSubmit,
    errors,
    touched,
    handleChange,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  console.log(isMobile, "ismobile");
  console.log(errors, "error");

  return (
    <>
      <div
        className={nav === true ? "head-mobile" : "head"}
        style={
          isMobile === true
            ? {
                backgroundSize: "550%",
                backgroundRepeat: "no-repeat",
              }
            : {
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
              }
        }
      >
        <nav>
          <ul>
            <li>
              <a href="#">
                <img src={Logo} className="logo" alt="logo" />
              </a>
            </li>
            {/* ===Mobile View=== */}
            <li className="navr-mobile">
              <div className="language">
                <a className="menu-cont" onClick={() => setNav(!nav)}>
                  {/* <img src={Menu} className='menu-icon'/> */}
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <div className="bg-respon">
          <div className="container forgot-pass">
            <p className="title-tron">Set New Password</p>
            <BaseForm
              formField={formField}
              touched={touched}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              errors={errors}
              setFieldValue={setFieldValue}
              values={values}
            />
            <Button
              className="mt-5"
              type="button"
              onClick={handleSubmit}
              // disabled={!isValid}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </div>
      <div className={nav === true ? "menu-view-active" : "menu-view"}>
        <nav>
          <ul>
            <li>
              <a href="#">
                <span>
                  <b style={{ fontWeight: "700" }}>Beri</b>
                  <span style={{ fontWeight: 500 }}>makna</span>
                </span>
              </a>
            </li>
            <li className="close-icon">
              <a className="menu-cont" onClick={() => setNav(!nav)}></a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Verification;

import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "../styles/layout.scss";
import { logout } from "../pages/authentication/auth.api";

export default function Layout({ children }) {
  const { value } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Container fluid className="home">
      <div className="navigation">
        <ul>
          <li>
            <Link to="/admin/dashboard">
              <span className="icon">
                <ion-icon name="desktop-outline"></ion-icon>
              </span>
              <span className="title">BeriMakna DB</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/dashboard">
              <span className="icon">
                <ion-icon name="speedometer-outline"></ion-icon>
              </span>
              <span className="title">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/campaign">
              <span className="icon">
                <ion-icon name="megaphone-outline"></ion-icon>
              </span>
              <span className="title">Campaign</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/campaigncreator">
              <span className="icon">
                <ion-icon name="color-palette-outline"></ion-icon>
              </span>
              <span className="title">Campaign Creator</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/user">
              <span className="icon">
                <ion-icon name="people-circle-outline"></ion-icon>
              </span>
              <span className="title">User</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/transactionhistory">
              <span className="icon">
                <ion-icon name="swap-horizontal-outline"></ion-icon>
              </span>
              <span className="title">Transaction History</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/article">
              <span className="icon">
                <ion-icon name="newspaper-outline"></ion-icon>
              </span>
              <span className="title">Article</span>
            </Link>
          </li>
          <li>
            <a href="#">
              <span className="icon">
                <ion-icon name="notifications-outline"></ion-icon>
              </span>
              <span className="title">Notification</span>
            </a>
          </li>
        </ul>
      </div>
      {/* ---- main ---- */}
      <div className="main">
        <div className="topbar">
          <div className="user">
            <Link to="/admin" onClick={() => handleLogout()}>
              <ion-icon name="person-circle-outline"></ion-icon>
            </Link>
          </div>
        </div>
        <div className="main-body">{children}</div>
      </div>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../styles/campaign.scss";
import { getCampaignList } from "./campaign.api";

// import { getUser } from './home.api';
// import { increment, incrementByAmount } from './home.reducer';

export default function Campaign() {
  const dispatch = useDispatch();
  const { campaignData } = useSelector((state) => state.campaign);

  useEffect(() => {
    dispatch(getCampaignList());
  }, []);

  console.log(campaignData, "campde");

  return (
    <Container fluid className="campaign">
      <span className="title">Campaign</span>
      <div className="button">
        <Link to="/admin/campaign/form">
          <h2 className="cardName">Create campaign</h2>
          <span className="iconBx">
            <ion-icon name="add-circle"></ion-icon>
          </span>
        </Link>
      </div>
      {/* <pre>
        {JSON.stringify(campaignData, null, 2)}
      </pre> */}
      <div className="cardBox">
        {campaignData.map((res, i) => (
          <div key={i} className="card">
            <Link to={`/admin/campaign/detail/${res._id}`}>
              <div>
                <div className="cardName">Campaign</div>
                <div className="title-container">
                  <div className="title">{res?.title}</div>
                </div>
                <div className="bottom-row">
                  <div className="typeName">
                    {res?.campaing_type === 0
                      ? "Donation"
                      : res?.campaing_type === 1
                      ? "Event"
                      : "Donation & Event"}
                  </div>
                  {res?.status === true ? (
                    <p className="status-active">Active</p>
                  ) : (
                    <p className="status-inactive">Inactive</p>
                  )}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Container>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCampaignList = createAsyncThunk(
  "campaign/getCampaignList",
  async (params, { rejectWithValue }) => {
    try {
      //   let accToken = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign`,
        {
          headers: {
            // Authorization: `Bearer ${accToken}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const postCampaign = createAsyncThunk(
  "campaign/postCampaign",
  async (data, { rejectWithValue }) => {
    console.log(data, "data di camp api");
    try {
      console.log(data, "data di api");
      // let accToken = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign`,
        data,
        {
          headers: {
            // "Content-Type": "multipart/form-data", use it if form data
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data, "data di api");
      // let accToken = await getToken();
      const response = await axios.patch(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign/${data.id}`,
        data,
        {
          headers: {
            // "Content-Type": "multipart/form-data", use it if form data
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getCampaignDetail = createAsyncThunk(
  "campaign/getCampaignDetail",
  async (id, { rejectWithValue }) => {
    try {
      //   let accToken = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data, "pai reducer");
      return response.data;
    } catch (err) {
      console.log(err.response);
      return rejectWithValue(err.response);
      // return error.response
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getCampaignUpdateList = createAsyncThunk(
  "campaign/getCampaignUpdateList",
  async (params, { rejectWithValue }) => {
    try {
      //   let accToken = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign`,
        {
          headers: {
            // Authorization: `Bearer ${accToken}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const deleteCampaignUpdateList = createAsyncThunk(
  "campaign/deleteCampaignUpdateList",
  async (id, { rejectWithValue }) => {
    try {
      //   let accToken = await getToken();
      const response = await axios.delete(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign-update/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const postCampaignUpdate = createAsyncThunk(
  "campaign/postCampaignUpdate",
  async (data, { rejectWithValue }) => {
    try {
      // let accToken = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/campaign-update`,
        data,
        {
          headers: {
            // "Content-Type": "multipart/form-data", use it if form data
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(data, "data di camp api");
      // console.log(response, "data di camp api");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const postCampaignUpdateNotif = createAsyncThunk(
  "campaign/postCampaignUpdateNotif",
  async (data, { rejectWithValue }) => {
    console.log(data, "data di camp api");
    try {
      // let accToken = await getToken();
      const response = await axios.post(
        "https://fcm-service.berimakna.com/api/notification/topic",
        data,
        {
          auth: {
            username: "admin@mail.me",
            password: "ekgEZN389d^X",
          },
        }
      );
      // console.log(response, "data di camp api");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

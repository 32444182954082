import React, { useState, useEffect } from "react";
import Layout from "./components/Layout";
import routes from "./utils/Routes";
import "./styles/main.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const authUser = localStorage.getItem("token");
  if (!authUser) return <Navigate to="/admin" />;
  return children;
};

const PublicRoute = ({ children }) => {
  const authUser = localStorage.getItem("token");
  if (authUser) return <Navigate to="/admin/dashboard" />;
  return children;
};

function App() {
  return (
    <div className="App body">
      <Router>
        <Routes>
          {routes.map(({ path, element, isProtected }) => (
            <Route
              key={path}
              path={path}
              element={
                isProtected ? (
                  <PrivateRoute>{<Layout>{element}</Layout>}</PrivateRoute>
                ) : (
                  <PublicRoute>{element}</PublicRoute>
                )
              }
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

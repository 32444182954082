
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// live server
const firebaseConfig = {
    apiKey: "AIzaSyDXB4sy0qqcm3BuJGuAaTY7EFG12Imif3E",
    authDomain: "berimaknadev.firebaseapp.com",
    projectId: "berimaknadev",
    storageBucket: "berimaknadev.appspot.com",
    messagingSenderId: "690389889915",
    appId: "1:690389889915:web:252fb7352682a233ea83de",
    measurementId: "G-R4KK82YRKT"
};

// firebase initt
const base = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase;

export default base.firestore();
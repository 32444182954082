import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../utils/config.json";

export const postRegister = createAsyncThunk(
  "auth/postRegister",
  async (data, { rejectWithValue }) => {
    console.log(data, "postregis");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/user/register`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
      // return error.response
    }
  }
);

export const postLogin = createAsyncThunk(
  "auth/getLogin",
  async (data, { rejectWithValue }) => {
    console.log(data, "data di api");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/admin/login`,
        data
      );
      return response;
    } catch (err) {
      // console.log(err.response);
      return rejectWithValue(err.response);
      // return error.response
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    // const keys = ['access_token', 'user_id'];
    const response = await localStorage.clear();
    return response;
  } catch (err) {
    return err;
    // return error.response
  }
});

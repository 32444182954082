import { createSlice } from "@reduxjs/toolkit";
import {
  getCampaignDetail,
  getCampaignList,
  postCampaign,
  postCampaignUpdate,
  updateCampaign,
  deleteCampaign,
  deleteCampaignUpdateList,
} from "../campaign/campaign.api";

const initialState = {
  loading: false,
  campaignData: [],
  campaignDetailData: {},
  listCampaignUpdate: [],
  listEventParticipant: [],
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers: {
    [getCampaignList.pending]: (state, action) => {
      state.loading = true;
    },
    [getCampaignList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.campaignData = payload.data;
    },
    [getCampaignList.rejected]: (state, action) => {
      state.loading = false;
    },
    [postCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [postCampaign.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postCampaign.rejected]: (state, action) => {
      state.loading = false;
    },
    [postCampaignUpdate.pending]: (state, action) => {
      state.loading = true;
    },
    [postCampaignUpdate.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postCampaignUpdate.rejected]: (state, action) => {
      state.loading = false;
    },
    [getCampaignDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getCampaignDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.campaignDetailData = payload.data;
      state.listCampaignUpdate = payload?.data?.campaign_update;
      state.listEventParticipant = payload?.data?.event_participant;
    },
    [getCampaignDetail.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCampaign.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateCampaign.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCampaign.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteCampaign.rejected]: (state, action) => {
      state.loading = false;
    },
    [deleteCampaignUpdateList.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCampaignUpdateList.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteCampaignUpdateList.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { decrement, incrementByAmount } = campaignSlice.actions;

export default campaignSlice.reducer;

import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function LandingPage() {
  return (
    <div className="home">
      <Navbar />
      <Footer />
    </div>
  );
}
export default LandingPage;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../utils/config.json";

export const getTransaction = createAsyncThunk(
  "get/getTransaction",
  async (params, { rejectWithValue }) => {
    console.log(params, "params");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/donate`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getCampaignDonation = createAsyncThunk(
  "get/CampaignDonation",
  async (id, { rejectWithValue }) => {
    // console.log(id, "params");
    try {
      const response = await axios.get(
        `${Config.berimakna_service}/api/donate?campaign_id=${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

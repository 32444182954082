import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import ReactQuill from "react-quill";
import { Table, Input } from "antd";
import "../../../styles/campaignDetail.scss";
import Pic from "../../../assets/sekolahalam.jpeg";
import {
  getCampaignDetail,
  getCampaignList,
  deleteCampaign,
  deleteCampaignUpdateList,
} from "../campaign.api";
import { getCampaignDonation } from "../../transaction/transaction.api";
import moment from "moment";
import Swal from "sweetalert2";
import { unwrapResult } from "@reduxjs/toolkit";

import { rupiahFormat } from "../../../utils/Helper";
import { Image } from "antd";

// import { getUser } from './home.api';
// import { increment, incrementByAmount } from './home.reducer';
const { Search } = Input;

export default function CampaignDetails() {
  const {
    campaignDetailData,
    listCampaignUpdate,
    listEventParticipant,
    loading,
  } = useSelector((state) => state.campaign);
  const { listCampaignDonation } = useSelector((state) => state.transaction);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [create, setCreate] = useState(false);
  const [campaignUpdate, setCampaignUpdate] = useState("");
  const [mergeData, setMergeData] = useState([]);
  const [Filter, setFilter] = useState({
    email: "",
  });

  // const openCreate = () => {
  //   setCreate(!create);
  // };

  useEffect(() => {
    // console.log(id)
    dispatch(getCampaignDetail(id));
  }, []);

  useEffect(() => {
    console.log(id, "masuk");
    dispatch(getCampaignDonation(id));
  }, [dispatch, id]);

  const handleChangeUpdate = (value) => {
    setCampaignUpdate(value);
  };

  const {
    title,
    description,
    start_date,
    end_date,
    campaign_images,
    campaing_type,
    donation_amount,
    date,
    donation,
    campaign_update,
    creator,
    collected_amount,
    participant,
    location,
    status,
  } = campaignDetailData;

  // console.log(id, "IDPAR");
  // console.log(campaignDetailData, "IDPAR");
  // console.log(campaignDetailData, "IDPAR");

  const onSearch = (value) => setFilter({ ...Filter, email: value });

  const columns = [
    {
      title: "Name",
      render: (v) => v?.user?.username,
      key: "username",
      fixed: "left",
      width: "fit-content",
    },
    {
      title: "Email",
      render: (v) => v?.user?.email,
      key: "email",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Transaction Time",
      dataIndex: "date",
      key: "date",
      render: (v) => moment(v).format("DD MMM YYYY HH:MM"),
    },
    // {
    //   title: "Status",
    //   dataIndex: "confirmed",
    //   key: "confirmed",
    //   render: (v) =>
    //     v ? (
    //       <span className="text-success">Active</span>
    //     ) : (
    //       <span className="text-danger">Inactive</span>
    //     ),
    //   sorter: (a, b) => a.confirmed - b.confirmed, //true false  values
    // },
    {
      title: "Total Donation",
      dataIndex: "donation",
      key: "confirmed",
      render: (v) => rupiahFormat(v),
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.donation - b.donation,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "campaignType",
    },
  ];

  const columnsCampaignUpdate = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "fit-content",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (v) => moment(v).format("DD MMM YYYY HH:MM"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: "fit-content",
      render: (_, obj) => (
        <div className="action-buttons" key={_}>
          <button onClick={() => handleDeleteCampaignUpdate(obj._id)}>
            <ion-icon name="trash"></ion-icon>
          </button>
          <button>
            <ion-icon name="create"></ion-icon>
          </button>
          <button>
            <ion-icon name="information-circle"></ion-icon>
          </button>
        </div>
      ),
    },
  ];

  const columnsParticipantList = [
    {
      title: "Name",
      dataIndex: ["user", "username"],
      key: "name",
      fixed: "left",
      width: "fit-content",
    },
    {
      title: "Email",
      dataIndex: ["user", "email"],
      key: "email",
      fixed: "left",
      width: "fit-content",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (v) => moment(v).format("DD MMM YYYY HH:MM"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: "fit-content",
      render: (_, obj) => (
        <div className="action-buttons" key={_}>
          <button onClick={() => handleDeleteCampaignUpdate(obj._id)}>
            <ion-icon name="trash"></ion-icon>
          </button>
          <button>
            <ion-icon name="create"></ion-icon>
          </button>
          <button>
            <ion-icon name="information-circle"></ion-icon>
          </button>
        </div>
      ),
    },
  ];

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCampaign(id))
          .then(unwrapResult)
          .then((res) => {
            Swal.fire("Deleted!", `Your file has been deleted `, "success");
            dispatch(getCampaignList());
            navigate("/admin/campaign");
          })
          .catch((err) => {
            Swal.fire("Failed!", `Your file failed to delete `, "error");
          });
      }
    });
  };

  const handleDeleteCampaignUpdate = (idCU) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCampaignUpdateList(idCU))
          .then(unwrapResult)
          .then((res) => {
            Swal.fire("Deleted!", `Your file has been deleted `, "success");
            dispatch(getCampaignDetail(id));
          })
          .catch((err) => {
            Swal.fire("Failed!", `Your file failed to delete `, "error");
          });
      }
    });
  };

  const handleSwitchStatus = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will change this status",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      console.log("berubah");
    });
  };

  console.log(listCampaignDonation, "listcampaiagn");

  return (
    <Container fluid className="campaign-detail">
      {/* <pre>
        {
          JSON.stringify(campaignDetailData, null, 2)
        }
      </pre> */}
      <div className="top-row">
        <div className="">
          <Link to="/admin/campaign">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Link>
          <span className="title">Campaign Details</span>
        </div>
        <button className="delete-icon" onClick={() => handleDelete()}>
          <div className="icon-container">
            <ion-icon name="trash-outline"></ion-icon>
            <p className="del-text">Delete</p>
          </div>
        </button>
      </div>
      <div className="info-detail">
        <div className="list">
          <div className="info-row">
            <Row>
              <Col sm={4}>
                <div className="info-container">
                  <p className="title">Campaign Name</p>
                  <p className="content">{title}</p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="info-container">
                  <p className="title">Campaign Type</p>
                  <p className="content">
                    {campaing_type === 0 && "Donation"}
                    {campaing_type === 1 && "Event"}
                    {campaing_type === 2 && "Donation & Event"}
                  </p>
                </div>
              </Col>
              {campaing_type === 0 || campaing_type === 2 ? (
                <Col sm={4}>
                  <div className="info-container">
                    <p className="title">Amount Money</p>
                    <p className="content">{rupiahFormat(donation_amount)}</p>
                  </div>
                </Col>
              ) : null}
              <Col sm={4}>
                <div className="info-container">
                  <p className="title">Campaign Creator</p>
                  <p className="content">{creator?.name}</p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="info-container">
                  <p className="title">Period</p>
                  <p className="content">
                    {moment(start_date).format("DD MMMM YYYY")} -{" "}
                    {moment(end_date).format("DD MMMM YYYY")}
                  </p>
                </div>
              </Col>
              {campaing_type === 1 || campaing_type === 2 ? (
                <Col sm={4}>
                  <div className="info-container">
                    <p className="title">Location</p>
                    <p className="content">{location}</p>
                  </div>
                </Col>
              ) : null}
              {campaing_type === 1 || campaing_type === 2 ? (
                <Col sm={4}>
                  <div className="info-container">
                    <p className="title">Participant</p>
                    <p className="content">{participant}</p>
                  </div>
                </Col>
              ) : null}
            </Row>
            {/* <div className="info-box">
            </div> */}

            {/* <div className="info-box">
            </div> */}
          </div>
          {/* <div className="about-container">
            <p className="title">Status</p>
            <p className="content">Active</p>
          </div> */}
          <div className="about-container">
            <p className="title">About</p>
            <p className="content">{description}</p>
          </div>
          <div className="creative-container">
            <p className="title">Creative</p>
            <Image.PreviewGroup>
              {campaign_images?.map((res, i) => (
                <div key={i}>
                  <Image
                    src={`${process.env.REACT_APP_BERIMAKNA_BASEURL}/${res}`}
                    width={250}
                    height={140}
                    style={{ objectFit: "cover" }}
                  />
                  &nbsp;
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
          <Link to={`/admin/campaign/form/${id}`}>
            <div className="button">
              <ion-icon name="create-outline"></ion-icon>
              {/* <ion-icon name="pencil-outline"></ion-icon> */}
              <span className="button-text">Edit</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="status-section">
        <p className="title">{create}</p>
        <div className="list">
          <div className="about-container">
            <p className="title">Campaign Status</p>
            <div className="status-row">
              {status === true ? (
                <p className="active">Active</p>
              ) : (
                <p className="inactive">Inactive</p>
              )}
              {status === true ? (
                <p className="switch" onClick={() => handleSwitchStatus()}>
                  Deactivate this campaign
                </p>
              ) : (
                <p className="switch" onClick={() => handleSwitchStatus()}>
                  Activate this campaign
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {campaing_type === 0 || campaing_type === 2 ? (
        <div className="progress-section">
          <div className="list">
            <span className="title">Progress</span>
            <div className="progress-bar">
              <div className="grey">
                <div
                  className="green"
                  style={{
                    width: `${(
                      (collected_amount / donation_amount) *
                      100
                    ).toFixed(2)}%`,
                  }}
                ></div>
              </div>
              <div className="progress-row">
                <span className="number">{rupiahFormat(collected_amount)}</span>
                <span className="percent">
                  {((collected_amount / donation_amount) * 100).toFixed(2)}%
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {campaing_type === 1 || campaing_type === 2 ? (
        <div className="campaign-update">
          <div className="list">
            <p className="title">Participant List</p>
            <Search
              placeholder="Search email"
              allowClear
              onSearch={onSearch}
              size="large"
              style={{
                maxWidth: "300px",
              }}
              className="mb-4"
            />
            <Table
              columns={columnsParticipantList}
              dataSource={listEventParticipant}
              size="middle"
              loading={loading}
              pagination={{
                size: 10,
                showSizeChanger: true,
                // onShowSizeChange: (e, v) => console.log(e, v),
                showTotal: (total, range) => (
                  <span style={{ left: 0, position: "absolute" }}>
                    Showing {range[0]} to {range[1]} of {total} results
                  </span>
                ),
              }}
              scroll={{
                x: "100%",
                // y: 300,
              }}
            />
          </div>
        </div>
      ) : null}
      <div className="campaign-update">
        <div className="list">
          <p className="title">Campaign Update</p>
          <Search
            placeholder="Search email"
            allowClear
            onSearch={onSearch}
            size="large"
            style={{
              maxWidth: "300px",
            }}
            className="mb-4"
          />
          <Table
            columns={columnsCampaignUpdate}
            dataSource={listCampaignUpdate}
            size="middle"
            loading={loading}
            pagination={{
              size: 10,
              showSizeChanger: true,
              // onShowSizeChange: (e, v) => console.log(e, v),
              showTotal: (total, range) => (
                <span style={{ left: 0, position: "absolute" }}>
                  Showing {range[0]} to {range[1]} of {total} results
                </span>
              ),
            }}
            scroll={{
              x: "100%",
              // y: 300,
            }}
          />
          <Link to={`/admin/campaign/detail/${id}/campaignupdate`}>
            <div className="button">
              <ion-icon name="create-outline"></ion-icon>
              {/* <ion-icon name="pencil-outline"></ion-icon> */}
              <span className="button-text">Create update</span>
            </div>
          </Link>
          {/* <ReactQuill
            theme="snow"
            value={campaignUpdate}
            onChange={handleChangeUpdate}
            modules={modules}
            formats={formats}
            className="quill"
          ></ReactQuill> */}
        </div>
      </div>
      <div className="transaction-list">
        <div className="list">
          <span className="title">Transaction History</span>
          <Search
            placeholder="Search email"
            allowClear
            onSearch={onSearch}
            size="large"
            style={{
              maxWidth: "300px",
            }}
            className="mb-4"
          />
          <Table
            columns={columns}
            dataSource={listCampaignDonation}
            size="middle"
            loading={loading}
            pagination={{
              size: 10,
              showSizeChanger: true,
              // onShowSizeChange: (e, v) => console.log(e, v),
              showTotal: (total, range) => (
                <span style={{ left: 0, position: "absolute" }}>
                  Showing {range[0]} to {range[1]} of {total} results
                </span>
              ),
            }}
            scroll={{
              x: "100%",
              // y: 300,
            }}
          />
        </div>
      </div>
    </Container>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "./user.api";

const initialState = {
    loading: false,
    listUser: [],
    listContributor: [],
    active: null,
    inactive: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: {
        [getUser.pending]: (state, action) => {
            state.loading = true;
        },
        [getUser.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.listUser = payload?.data;
            state.listContributor = payload?.contributor;
            state.active = payload?.active_user;
            state.inactive = payload?.inactive_user;
        },
        [getUser.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = userSlice.actions;

export default userSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getArticle = createAsyncThunk(
  "article/getArticle",
  async (data, { rejectWithValue }) => {
    console.log(data, "data");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/article`,
        {
          params: data,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const postArticle = createAsyncThunk(
  "article/postArticle",
  async (data, { rejectWithValue }) => {
    console.log(data, "data di camp api");
    try {
      console.log(data, "data di api");
      // let accToken = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/article`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const patchArticle = createAsyncThunk("article/patchArticle", async (data, { rejectWithValue }) => {
  console.log(data, "data di camp api");
  const form = data.formData
  try {
    console.log(data, "data di api");
    // let accToken = await getToken();
    const response = await axios.patch(`${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/article/${data.id}`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
}
);

export const deleteArticle = createAsyncThunk(
  "get/deleteArticle",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/article/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getArticleDetail = createAsyncThunk(
  "article/getArticleDetail",
  async (id, { rejectWithValue }) => {
    console.log(id, "data");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/article/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

import React from "react";
import { Form, Col, Row, Button, InputGroup } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { DatePicker, Space } from "antd";
import ReactQuill from "react-quill";

import "../styles/baseForm.scss";
import { useDispatch } from "react-redux";
import { postMultipleImages } from "../shared.api";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function BaseForm({
  formField,
  values,
  handleSubmit,
  errors,
  touched,
  handleChange,
  setFieldValue,
  hiddenSubmit,
}) {
  const dispatch = useDispatch();
  // const { helperImages } = useSelector(state => state.shared)

  const handleImageUpload = (id, val, type) => {
    if (type === "image") {
      setFieldValue(id, val);
    }

    if (type === "images") {
      const formData = new FormData();
      val.forEach((file) => {
        formData.append("images", file.file);
      });
      dispatch(postMultipleImages(formData))
        .then(unwrapResult)
        .then((res) => {
          console.log(res, "helper images");
          setFieldValue(id, [...values[id], ...res]);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Image ipload error",
          });
        });
    }
  };

  const handleDeleteImage = (img, id) => {
    const filtered = values[id].filter((val) => val !== img);
    setFieldValue(id, filtered);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          {formField.map((res, i) =>
            //TEXT
            res.type === "text" ? (
              <Form.Group as={Col} className="mb-4" md="7" key={i}>
                <Form.Label style={{ color: res.style }}>
                  {res.label}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={res.placeholder}
                  name={res.id}
                  value={values[res.id]}
                  onChange={handleChange}
                  isInvalid={!!errors[res.id]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[res.id]}
                </Form.Control.Feedback>
              </Form.Group>
            ) : //NUMBER
            res.type === "number" ? (
              <Form.Group as={Col} className="mb-4" md="7" key={i}>
                <Form.Label>{res.label}</Form.Label>
                <Form.Control
                  type="number"
                  step="any"
                  placeholder={res.placeholder}
                  name={res.id}
                  value={values[res.id]}
                  onChange={handleChange}
                  isInvalid={!!errors[res.id]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[res.id]}
                </Form.Control.Feedback>
              </Form.Group>
            ) : //TEXTAREA
            res.type === "textarea" ? (
              <Form.Group as={Col} className="mb-4" md="7" key={i}>
                <Form.Label>{res.label}</Form.Label>
                <Form.Control
                  placeholder={res.placeholder}
                  as="textarea"
                  name={res.id}
                  value={values[res.id]}
                  onChange={handleChange}
                  isInvalid={!!errors[res.id]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[res.id]}
                </Form.Control.Feedback>
              </Form.Group>
            ) : //IMAGE
            res.type === "images" || res.type === "image" ? (
              <Form.Group as={Col} className="mb-4" md="7" key={i}>
                <Form.Label>{res.label}</Form.Label>
                <ImageUploading
                  value={values[res.id]}
                  onChange={(val) => handleImageUpload(res.id, val, res.type)}
                  maxNumber={69}
                  dataURLKey="data_url"
                  multiple={res.type === "images" ? true : false}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div
                      className={` ${
                        errors[res.id] && "error-line"
                      } upload__image-wrapper border-1 rounded d-flex align-items-center justify-content-center`}
                      style={{ minHeight: 100, maxWidth: 200 }}
                    >
                      <div
                        className="d-flex border p-1 align-items-center"
                        type="div"
                        style={{ cursor: "pointer" }}
                      >
                        {values[res.id].map((img, idx) => (
                          <div key={idx} className="wrapimg">
                            {res.type === "images" && (
                              <button
                                type="button"
                                onClick={() => handleDeleteImage(img, res.id)}
                              >
                                X
                              </button>
                            )}
                            <img
                              src={
                                img["data_url"] ||
                                `${process.env.REACT_APP_BERIMAKNA_BASEURL}/${img}`
                              }
                              style={{ maxHeight: 100 }}
                              alt=""
                            />
                          </div>
                        ))}
                        <button type="button" onClick={onImageUpload}>
                          {res.type === "images"
                            ? "+ Add photo"
                            : "Change Photo"}
                        </button>
                      </div>
                    </div>
                  )}
                </ImageUploading>
                <div className="error">{errors[res.id]}</div>
              </Form.Group>
            ) : //SELECT
            res.type === "select" ? (
              <Form.Group as={Col} className="mb-4" md="7" key={i}>
                <Form.Label>{res.label}</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name={res.id}
                  value={values[res.id]}
                  onChange={handleChange}
                  isInvalid={!!errors[res.id]}
                >
                  {res.opt.map((val, key) => (
                    <option key={key} value={val.value}>
                      {val.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {!!errors[res.id]}
                </Form.Control.Feedback>
              </Form.Group>
            ) : //DATE
            res.type === "daterange" ? (
              <Form.Group as={Col} className="mb-4" md="7" key={i}>
                <Form.Label>{res.label}</Form.Label>
                <br />

                <RangePicker
                  size="large"
                  onChange={(_, v) => {
                    setFieldValue(res.id[0], v[0]);
                    setFieldValue(res.id[1], v[1]);
                  }}
                  value={
                    values[res.id[0]].length !== 0 && [
                      moment(values[res.id[0]], "YYYY/MM/DD"),
                      moment(values[res.id[1]], "YYYY/MM/DD"),
                    ]
                  }
                  status={errors[res.id[0]] && errors[res.id[1]] && "error"}
                />

                <div className="error">
                  {errors[res.id[0]] && errors[res.id[1]]}
                </div>
              </Form.Group>
            ) : res.type === "quill" ? (
              <Form.Group as={Col} className="mb-4" md="7" key={i}>
                <Form.Label>{res.label}</Form.Label>
                <br />
                <ReactQuill
                  theme="snow"
                  value={values[res.id]}
                  onChange={(v) => setFieldValue(res.id, v)}
                  modules={modules}
                  formats={formats}
                  className="quilled"
                ></ReactQuill>
                <div className="error">{errors[res.id]}</div>
              </Form.Group>
            ) : null
          )}
        </Row>

        {/* <pre>{JSON.stringify(values, null, 2)}</pre>

        <pre>{JSON.stringify(errors, null, 2)}</pre> */}
        {hiddenSubmit && (
          <Button className="mt-5" type="submit">
            Submit form
          </Button>
        )}
      </Form>
    </>
  );
}

// EXAMPLE DATA FORM
// const formField = [
//     TEXT
//     { id: 'name_brand', type: 'text', label: 'Nama Brand', placeholder: 'Ketikan Nama Brand'},
//     TEXTAREA
//     { id: 'description', type: 'textarea', label: 'Deskripsi', placeholder: 'Ketikan Deskripsi'},
//     IMAGE
//     { id: 'image', type: 'image', label: 'Image', placeholder: ''},
//     OPTION
//      {
//         id: 'product_id', type: 'select', label: 'Nama Produk', placeholder: 'Masukan Produk', opt: [
//             { value: 'publish', label: 'PUBLISH' },
//             { value: 'draft', label: 'DRAFT' },
//         ]
//     },
// ]

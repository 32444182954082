import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Accordion,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "../../styles/authentication.scss";

import { postLogin } from "./auth.api";

import { unwrapResult } from "@reduxjs/toolkit";
import axios from "axios";

export default function Authentication(props) {
  const dispatch = useDispatch();
  const { loading, login } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username] = useState("flashy");
  // const [email] = useState('flashy@gmail.com');
  const [no_hp] = useState("08123123123");
  const [role] = useState("user");
  const [regData] = useState({
    email: "flashy@gmail.com",
    username: "flashy",
    password: "qwerty",
    no_hp: "08123456789",
    role: "user",
  });

  // useEffect(() => {
  //   console.log(process.env.REACT_APP_BERIMAKNA_BASEURL);
  // }, []);

  const handleLogin = (e) => {
    e.preventDefault()
    const data = {
      email: email,
      password: password,
    };
    dispatch(postLogin(data))
      .then(unwrapResult)
      .then((res) => {
        console.log(res);
        console.log(localStorage.getItem("token"));
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "Login succeed",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        })
      })
      .catch((err) => {
        console.log(err.data)
        Swal.fire({
          icon: "error",
          title: 'Login Failed',
          text: err.data || ''
        });
      });
  };

  return (
    <Container fluid className="auth">
      <div className="login-box">
        <span className="login-text">LOGIN - BM Dashboard</span>
        <form id="tes" onSubmit={handleLogin}>
          <Form.Group className="mt-4 mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              autoComplete="on"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button type="submit" className="button mt-3" >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
}

import React, { useEffect, useState } from "react";

// import ScrollspyNav from "react-scrollspy-nav";
import Logo from "../../../assets/BeriMakna_Logo.png";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function ResetPasswordSuccess() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [nav, setNav] = useState(false);
  const [menu, setMenu] = useState(false);

  console.log(isMobile, "ismobile");

  return (
    <>
      <div
        className={nav === true ? "head-mobile" : "head"}
        style={
          isMobile === true
            ? {
                backgroundSize: "550%",
                backgroundRepeat: "no-repeat",
              }
            : {
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
              }
        }
      >
        <nav>
          <ul>
            <li>
              <a href="#">
                <img src={Logo} className="logo" alt="logo" />
              </a>
            </li>
            {/* ===Mobile View=== */}
            <li className="navr-mobile">
              <div className="language">
                <a className="menu-cont" onClick={() => setNav(!nav)}>
                  {/* <img src={Menu} className='menu-icon'/> */}
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <div className="bg-respon">
          <div className="container barracuda">
            <p className="title-tron">Congratulations!</p>
            <p className="title-desc">
              Your new password is created. <br /> Go to Berimakna App and login
              to start giving your contribution.
            </p>
          </div>
        </div>
      </div>
      <div className={nav === true ? "menu-view-active" : "menu-view"}>
        <nav>
          <ul>
            <li>
              <a href="#">
                <span>
                  <b style={{ fontWeight: "700" }}>Beri</b>
                  <span style={{ fontWeight: 500 }}>makna</span>
                </span>
              </a>
            </li>
            <li className="close-icon">
              <a className="menu-cont" onClick={() => setNav(!nav)}></a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default ResetPasswordSuccess;

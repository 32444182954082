import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../../styles/formCampaign.scss";
import { Container } from "react-bootstrap";
import { Form, Col, Row, Button, InputGroup } from "react-bootstrap";

import BaseForm from "../../shared/components/BaseForm";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../shared/shared.api";
import { getCreator } from "../../creator/creator.api";
import {
  getCampaignDetail,
  postCampaign,
  updateCampaign,
} from "../campaign.api";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

export default function FormCampaign() {
  const dispatch = useDispatch();
  const { id } = useParams();
  let navigate = useNavigate();

  const [listCreator, setListCreator] = useState([]);

  const [initialValueDetail, setInitialValueDetail] = useState({});

  useEffect(() => {
    handleGetDataDetail();
    dispatch(getCreator())
      .then(unwrapResult)
      .then((res) => {
        const dataopt = res.data.map((res) => ({
          label: res.name,
          value: res._id,
        }));
        setListCreator([{ label: "Pilih Creator", value: "" }, ...dataopt]);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error get creator",
        });
      });
  }, []);

  const handleGetDataDetail = () => {
    dispatch(getCampaignDetail(id))
      .then(unwrapResult)
      .then((res) => {
        console.log(res, "detail");

        const data = {
          title: res.data.title,
          description: res.data.description,
          campaing_type: res.data.campaing_type + "",
          campaign_images: res.data.campaign_images,
          start_date: res.data.start_date,
          end_date: res.data.end_date,
          status: res.data.status ? "active" : "inactive",
          creator: res.data.creator._id,
          donation_amount: res.data.donation_amount,
          location: res.data.location,
          participant: res.data.participant,
        };

        setInitialValueDetail(data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error get creator",
        });
      });
  };

  const originalForm = [
    {
      id: "campaing_type",
      type: "select",
      label: "Campaign Type",
      placeholder: "Select campaign type",
      opt: [
        {
          label: "Donation",
          value: "0",
        },
        {
          label: "Event",
          value: "1",
        },
        {
          label: "Donation & Event",
          value: "2",
        },
      ],
    },
    {
      id: "title",
      type: "text",
      label: "Event name",
      placeholder: "Insert event name",
    },
    {
      id: "description",
      type: "textarea",
      label: "About event",
      placeholder: "Insert about campaign",
    },
    {
      id: "creator",
      type: "select",
      label: "Creator",
      placeholder: "Select creator",
      opt: listCreator,
    },
    {
      id: "donation_amount",
      type: "number",
      label: "Amount Money",
      placeholder: "Insert amount money",
    },
    {
      id: "location",
      type: "text",
      label: "Event location",
      placeholder: "Insert event location",
    },
    {
      id: "participant",
      type: "text",
      label: "Number of participants",
      placeholder: "Insert number",
    },
    {
      id: ["start_date", "end_date"],
      type: "daterange",
      label: "Campaign Period",
    },
    {
      id: "status",
      type: "select",
      label: "Status",
      placeholder: "Select status",
      opt: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
    },
    {
      id: "campaign_images",
      type: "images", //multiple
      label: "Upload Creative",
    },
  ];

  const formik = useFormik({
    initialValues: {
      campaing_type: initialValueDetail.campaing_type,
      title: initialValueDetail.title,
      description: initialValueDetail.description,
      creator: initialValueDetail.creator,
      donation_amount: initialValueDetail.donation_amount,
      location: initialValueDetail.location,
      participant: initialValueDetail.participant,
      start_date: initialValueDetail.start_date,
      end_date: initialValueDetail.end_date,
      status: initialValueDetail.status,
      campaign_images: initialValueDetail.campaign_images,
    },
    validationSchema: Yup.object().shape({
      campaing_type: Yup.string().required(),
      title: Yup.string().required(),
      description: Yup.string().required(),
      creator: Yup.string().required(),
      donation_amount: Yup.number(),
      location: Yup.string(),
      participant: Yup.number(),
      start_date: Yup.string().required(),
      end_date: Yup.string().required(),
      status: Yup.string().required(),
      campaign_images: Yup.array().min(1).required(),
    }),
    enableReinitialize: true,

    onSubmit: (values) => {
      if (values?.campaing_type === "0") {
        // donation
        delete values.location;
        delete values.participant;
      }

      if (values?.campaing_type === "1") {
        // event
        delete values.donation_amount;
      }

      values.status = values.status === "active" ? true : false;
      values.campaing_type = parseInt(values.campaing_type); /// DONT EDIT THE TYPO "CAMPAING" <<<
      values.id = id;
      dispatch(updateCampaign(values))
        .then(unwrapResult)
        .then((res) => {
          console.log(res);
          Swal.fire({
            icon: "success",
            title: "Your data has been updated",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            navigate("/admin/campaign");
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            // position: 'top-end',
            icon: "error",
            title: "Your data cannot updated",
          });
        });
    },
  });

  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    formik;

  const [dataForm, setDataForm] = useState([]);

  useEffect(() => {
    if (values?.campaing_type === "0") {
      // donation
      const newData = originalForm.filter(
        (val) => val.id !== "location" && val.id !== "participant"
      );
      setDataForm(newData);
    }

    if (values?.campaing_type === "1") {
      // event
      const newData = originalForm.filter(
        (val) => val.id !== "donation_amount"
      );
      setDataForm(newData);
    }

    if (values?.campaing_type === "2") {
      // all
      setDataForm(originalForm);
    }
  }, [values?.campaing_type, listCreator]);

  return (
    <Container fluid className="form-campaign">
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      <div className="top-row">
        <div className="title-container">
          <Link to={`/admin/campaign/detail/${id}`}>
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Link>
          <span className="title">Create Campaign</span>
        </div>

        <BaseForm
          formField={dataForm}
          touched={touched}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          errors={errors}
          setFieldValue={setFieldValue}
          values={values}
          hiddenButton={true}
        />
        <Button className="mt-5" type="button" onClick={handleSubmit}>
          Submit form
        </Button>
      </div>
    </Container>
  );
}

// "name_product": "bridgstone",
//     "product_code": "xx-xx-xx",
//     "brand_id": "73a45012-36d8-4f37-93db-5b66e3e9f97e",
//     "product_category_id": "5a1d9597-2e56-4681-a933-77fcada09cb4",
//     "type_product_id": "ban racing",
//     "created_by" : "xx-xx-xx"

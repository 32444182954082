import React, { useState, useEffect } from "react";
import moment from "moment";
import { Container, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteCreator, getCreator } from "./creator.api";
import Swal from "sweetalert2";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { Table, Input, Image } from "antd";
import "../../styles/campaignCreator.scss";
import { unwrapResult } from "@reduxjs/toolkit";
const { Search } = Input;

export default function CampaignCreator() {
  const { value } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [create, setCreate] = useState(false);
  const { listCreator, loading } = useSelector((state) => state.creator);

  const openCreate = () => {
    setCreate(!create);
  };

  const [Filter, setFilter] = useState({
    name: "",
  });

  const onSearch = (value) => setFilter({ ...Filter, name: value });

  useEffect(() => {
    dispatch(getCreator(Filter.name));
  }, [Filter]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCreator(id))
          .then(unwrapResult)
          .then((res) => {
            Swal.fire("Deleted!", `Your file has been deleted `, "success");
            dispatch(getCreator());
          })
          .catch((err) => {
            Swal.fire("Failed!", `Your file failed to delete `, "error");
          });
      }
    });
  };

  return (
    <Container fluid className="creator">
      <span className="title">Campaign Creator</span>
      {/* <pre>
        {
          JSON.stringify(listCreator, null, 2)
        }
      </pre> */}
      <div className="button">
        <Link to="/admin/campaigncreator/form">
          <h2 className="cardName">Create campaign cretor</h2>
          <span className="iconBx">
            <ion-icon name="add-circle"></ion-icon>
          </span>
        </Link>
      </div>
      <Search
        placeholder="Search email"
        allowClear
        onSearch={onSearch}
        size="large"
        style={{
          maxWidth: "300px",
        }}
        className="mb-4"
      />
      <Table
        columns={[
          {
            title: "Full Name",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            width: "fit-content",
            render: (v, obj) => (
              <div className="d-flex align-items-center">
                <Image
                  src={`${process.env.REACT_APP_BERIMAKNA_BASEURL}/${obj.photo}`}
                  width={35}
                />
                <span className="ms-2">{v}</span>
              </div>
            ),
          },
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "Join Date",
            dataIndex: "date",
            key: "date",
            render: (v) => moment(v).format("DD MMM YYYY"),
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          },
          {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: "fit-content",
            render: (_, obj) => (
              <div className="action-buttons">
                <button onClick={() => handleDelete(obj._id)}>
                  <ion-icon name="trash"></ion-icon>
                </button>
                <Link to={`/admin/campaigncreator/${obj._id}`}>
                  <button>
                    <ion-icon name="create"></ion-icon>
                  </button>
                </Link>
                <button>
                  <ion-icon name="information-circle"></ion-icon>
                </button>
              </div>
            ),
          },
        ]}
        dataSource={listCreator}
        size="middle"
        loading={loading}
        pagination={{
          size: 10,
          showSizeChanger: true,
          // onShowSizeChange: (e, v) => console.log(e, v),
          showTotal: (total, range) => (
            <span style={{ left: 0, position: "absolute" }}>
              Showing {range[0]} to {range[1]} of {total} results
            </span>
          ),
        }}
        scroll={{
          x: "100%",
          // y: 300,
        }}
      />
    </Container>
  );
}

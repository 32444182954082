import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'

export default function Play() {
    const [data, setData] = useState({
        phone: '',
        price: 0,
    })

    const handleChange = (e) => {
        setData({
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {

        e.preventDefault()


        const body = {
            "reference_id": `order-id-${moment().unix()}`,
            "currency": "IDR",
            "amount": data.price,
            "checkout_method": "ONE_TIME_PAYMENT",
            "channel_code": "ID_OVO",
            "channel_properties": {
                "mobile_number": `+62${data.phone}`
            },
            "metadata": {
                "name": "Kemal"
            }
        }


        var config = {
            method: 'post',
            url: 'https://api.xendit.co/ewallets/charges',
            headers: {
                'Authorization': 'Basic eG5kX2RldmVsb3BtZW50X1N3SzJNQVBCVHZOcEQwalJpZzhUSUZaTndOSVpBZ1lmUkpEejBwY2tQRExLQUVSMEl4ZzliMU9SU3RhTXo3S1g6',
                'Content-Type': 'application/json',
                'Cookie': 'incap_ses_219_2182539=15eEQbqNzjdMgZSDqAsKA9W4NWMAAAAAgCDb3Z0aDNbNOxb2BDf5YA==; nlbi_2182539=WCTvaDy/OmGek506/0ZNTgAAAAAwoQB/YmALntZzPk6cM0C9'
            },
            data: body
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    return (
        <div>
            <div className="container-sm">
                <form onSubmit={handleSubmit}>

                    <label>phone</label> <br />
                    <div className="d-flex align-items-center">
                        +62<input name='phone' onChange={handleChange} type="text" />
                    </div> <br />

                    <label>nominal</label> <br />
                    <input name='price' onChange={handleChange} type="number" />


                    <button type='submit'>submit</button>

                </form>

            </div>
        </div>
    )
}

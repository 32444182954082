import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import "../../../styles/formCampaign.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Dropdown,
  DropdownButton,
  InputGroup,
  Button,
} from "react-bootstrap";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { getCreatorDetail, patchCreator } from "../creator.api";
import BaseForm from "../../shared/components/BaseForm";

export default function EditFormCreator() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [initialValueDetail, setInitialValueDetail] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    handleGetDataDetail();
  }, []);

  const handleGetDataDetail = () => {
    dispatch(getCreatorDetail(id))
      .then(unwrapResult)
      .then((res) => {
        console.log(res, "detail");
        const data = {
          name: res.data.name,
          title: res.data.title,
          about: res.data.about,
          photo: [
            {
              data_url:
                process.env.REACT_APP_BERIMAKNA_BASEURL + "/" + res.data.photo,
            },
          ],
        };
        setInitialValueDetail(data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error get creator",
        });
      });
  };

  const originalForm = [
    {
      id: "name",
      type: "text",
      label: "Creator Name",
      placeholder: "Insert creator name",
    },
    {
      id: "title",
      type: "text",
      label: "Creator Title",
      placeholder: "Insert creator title",
    },
    {
      id: "about",
      type: "textarea",
      label: "About Creator",
      placeholder: "Insert about creator",
    },
    {
      id: "photo",
      type: "image",
      label: "Upload Creative",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: initialValueDetail.name || "",
      title: initialValueDetail.title || "",
      about: initialValueDetail.about || "",
      photo: initialValueDetail.photo || [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      title: Yup.string().required(),
      about: Yup.string().required(),
      photo: Yup.array().min(1).required(),
    }),
    enableReinitialize: true,

    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("about", values.about);
      formData.append("name", values.name);

      values.photo.forEach((file) => {
        formData.append("photo", file.file);
      });

      const data = {
        formData: formData,
        id: id,
      };

      dispatch(patchCreator(data))
        .then(unwrapResult)
        .then((res) => {
          console.log(res);
          Swal.fire({
            // position: 'top-end',
            icon: "success",
            title: "Your data has been saved",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            navigate("/admin/campaigncreator");
          });
        })
        .catch((err) => {
          Swal.fire({
            // position: 'top-end',
            icon: "error",
            title: "Your data cannot saved",
          });
          console.log(err.response);
        });
    },
  });

  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    formik;

  return (
    <Container fluid className="form-campaign">
      <div className="top-row">
        <div className="title-container">
          <Link to="/admin/campaigncreator">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Link>
          <span className="title">Edit Campaign Creator</span>
        </div>
      </div>
      <pre>
        {/* {
          JSON.stringify(values, null, 2)
        } */}
      </pre>
      <BaseForm
        formField={originalForm}
        touched={touched}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={errors}
        setFieldValue={setFieldValue}
        values={values}
      />
      <Button className="mt-5" type="button" onClick={handleSubmit}>
        Submit form
      </Button>
    </Container>
  );
}

// "name_product": "bridgstone",
//     "product_code": "xx-xx-xx",
//     "brand_id": "73a45012-36d8-4f37-93db-5b66e3e9f97e",
//     "product_category_id": "5a1d9597-2e56-4681-a933-77fcada09cb4",
//     "type_product_id": "ban racing",
//     "created_by" : "xx-xx-xx"

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../../../styles/formCampaign.scss";
import BaseForm from "../../shared/components/BaseForm";
import { Container, Button } from "react-bootstrap";
import { getArticleDetail, patchArticle } from "../article.api";
// import db from "../../../firebaseInit";
// import { getDoc } from "@firebase/firestore";
// import moment from "moment";
import { postArticle } from "../article.api";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

export default function EditFormArticle() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [initialValueDetail, setInitialValueDetail] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    handleGetDataDetail();
  }, []);

  const handleGetDataDetail = () => {
    dispatch(getArticleDetail(id))
      .then(unwrapResult)
      .then((res) => {
        console.log(res, "detail");

        const data = {
          title: res.data.title,
          author: res.data.author,
          content: res.data.content,
          banner: [
            {
              data_url:
                process.env.REACT_APP_BERIMAKNA_BASEURL + "/" + res.data.banner,
            },
          ],
        };

        setInitialValueDetail(data);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error get creator",
        });
      });
  };

  const originalForm = [
    {
      id: "author",
      type: "text",
      label: "Author",
      placeholder: "Insert author",
    },
    {
      id: "title",
      type: "text",
      label: "Article title",
      placeholder: "Insert article title",
    },
    {
      id: "banner",
      type: "image",
      label: "Upload banner",
    },
    {
      id: "content",
      type: "quill",
      label: "Article Content",
    },
  ];

  const formik = useFormik({
    initialValues: {
      title: initialValueDetail.title || "",
      author: initialValueDetail.author || "",
      content: initialValueDetail.content || "",
      banner: initialValueDetail.banner || [],
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      author: Yup.string().required(),
      content: Yup.string().required(),
      banner: Yup.array().min(1).required(),
    }),
    enableReinitialize: true,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("author", values.author);
      formData.append("title", values.title);
      formData.append("content", values.content);

      if (values.banner.some((element) => element.file)) {
        values.banner.forEach((file) => {
          formData.append("banner", file.file);
        });
      }

      const data = {
        formData: formData,
        id: id,
      };

      dispatch(patchArticle(data))
        .then(unwrapResult)
        .then((res) => {
          // console.log(res);
          Swal.fire({
            icon: "success",
            title: "Your data has been saved",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            navigate("/article");
          });
        })
        .catch((err) => {
          Swal.fire({
            // position: 'top-end',
            icon: "error",
            title: "Your data cannot saved",
          });
          console.log(err.response);
        });
    },
  });

  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    formik;
  // const [dataForm, setDataForm] = useState([]);

  //   useEffect(() => {
  //     setDataForm(originalForm);
  //   });

  return (
    <Container fluid className="form-campaign">
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      <div className="top-row">
        <div className="title-container">
          <Link to="/admin/article">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Link>
          <span className="title">Edit Article</span>
        </div>
      </div>
      <BaseForm
        formField={originalForm}
        touched={touched}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={errors}
        setFieldValue={setFieldValue}
        values={values}
      />
      <Button className="mt-5" type="button" onClick={handleSubmit}>
        Submit article
      </Button>
      {/* 
      <button type="button" onClick={handleSendNotif}>
        send notif
      </button> */}
    </Container>
  );
}

// "name_product": "bridgstone",
//     "product_code": "xx-xx-xx",
//     "brand_id": "73a45012-36d8-4f37-93db-5b66e3e9f97e",
//     "product_category_id": "5a1d9597-2e56-4681-a933-77fcada09cb4",
//     "type_product_id": "ban racing",
//     "created_by" : "xx-xx-xx"

import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  InputGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Table, Input } from "antd";
import "../../styles/transactionHistory.scss";

import moment from "moment";
import { rupiahFormat } from "../../utils/Helper";
import { getTransaction } from "./transaction.api";

// import { getUser } from './home.api';
// import { increment, incrementByAmount } from './home.reducer';

const { Search } = Input;


export default function TransactionHistory() {
  const { value, loading } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [create, setCreate] = useState(false);
  const [mergeData, setMergeData] = useState([]);
  const [Filter, setFilter] = useState({
    email: "",
  });

  const openCreate = () => {
    setCreate(!create);
  };

  useEffect(() => {
    dispatch(getTransaction())
  }, [])

  const onSearch = (value) => setFilter({ ...Filter, email: value });

  console.log(create, "tes");

  return (
    <Container fluid className="transaction">
      <span className="title">Transaction History</span>
      <div className="transaction-list">
        <div className="list">
          <div className="header-container"></div>
          <Search
            placeholder="Search email"
            allowClear
            onSearch={onSearch}
            size="large"
            style={{
              maxWidth: "300px",
            }}
            className="mb-4"
          />
          <Table
            columns={[
              {
                title: "Name",
                dataIndex: "username",
                key: "username",
                fixed: "left",
                width: "fit-content",
              },
              {
                title: "Email",
                dataIndex: "email",
                key: "email",
              },
              {
                title: "Payment Method",
                dataIndex: "_id",
                key: "_id",
              },
              {
                title: "Transaction Time",
                dataIndex: "date",
                key: "date",
                render: (v) => moment(v).format("DD MMM YYYY HH:MM"),
              },
              // {
              //   title: "Status",
              //   dataIndex: "confirmed",
              //   key: "confirmed",
              //   render: (v) =>
              //     v ? (
              //       <span className="text-success">Active</span>
              //     ) : (
              //       <span className="text-danger">Inactive</span>
              //     ),
              //   sorter: (a, b) => a.confirmed - b.confirmed, //true false  values
              // },
              {
                title: "Total Donation",
                dataIndex: "donation",
                key: "confirmed",
                render: (v) => rupiahFormat(v),
                // defaultSortOrder: 'descend',
                sorter: (a, b) => a.donation - b.donation,
              },
              {
                title: "Campaign Name",
                dataIndex: "campaignName",
                key: "campaignName",
              },
              {
                title: "Campaign Type",
                dataIndex: "campaignType",
                key: "campaignType",
              },
            ]}
            dataSource={mergeData}
            size="middle"
            loading={loading}
            pagination={{
              size: 10,
              showSizeChanger: true,
              // onShowSizeChange: (e, v) => console.log(e, v),
              showTotal: (total, range) => (
                <span style={{ left: 0, position: "absolute" }}>
                  Showing {range[0]} to {range[1]} of {total} results
                </span>
              ),
            }}
            scroll={{
              x: "100%",
              // y: 300,
            }}
          />
          {/* <div className="header">
                    <h5>List</h5>
                </div> */}
          {/* <div className="table-style">
                    <table>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                                <td>Payment Method</td>
                                <td>Total Donation</td>
                                <td>Transaction Time</td>
                                <td>Campaign Name</td>
                                <td>Campaign Type</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>Mandiri Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>BCA Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>Mandiri Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Event</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>BCA Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation & Event</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>Mandiri Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>BCA Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>Mandiri Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>BCA Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>Mandiri Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                            <tr>
                                <td>Joni Si Playboy</td>
                                <td>joni@gmail.com</td>
                                <td>BCA Virtual</td>
                                <td>Rp 200,000</td>
                                <td>25/03/22 14.30 WIB</td>
                                <td>Sekolah Alam</td>
                                <td>Donation</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
        </div>
      </div>
    </Container>
  );
}

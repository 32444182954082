import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../utils/config.json";

export const getCategory = createAsyncThunk("get/getCategory", async (params, { rejectWithValue }) => {
    // console.log(params, "params");
    try {
        const response = await axios.get(`${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/category`, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        });
        console.log(response.data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response);
    }
});

export const postMultipleImages = createAsyncThunk("post/postMultipleImages", async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/helper/multiple-images`,
            data,
            {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response);
    }
});


export const deleteCreator = createAsyncThunk("get/deleteCreator", async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BERIMAKNA_BASEURL}/api/creator/${id}`, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response);
    }
});

import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  InputGroup,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";

import "../../../styles/campaignDetail.scss";
import { postCampaignUpdate, postCampaignUpdateNotif } from "../campaign.api";
import Swal from "sweetalert2";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseForm from "../../shared/components/BaseForm";
import { replace, useFormik } from "formik";
import * as Yup from "yup";

import { v4 as uuidv4 } from "uuid";
import db from "../../../firebaseInit";
const messageRef = db.collection("NOTIF_DATA");

export default function CampaignUpdateForm() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const { campaignDetailData, loading } = useSelector(
    (state) => state.campaign
  );
  const [campaignUpdate, setCampaignUpdate] = useState("");
  const handleChangeUpdate = (value) => {
    setCampaignUpdate(value);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      campaign: id,
      description: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      campaign: Yup.string().required(),
      description: Yup.string().required(),
    }),
    onSubmit: (values) => {
      dispatch(postCampaignUpdate(values))
        .then(unwrapResult)
        .then((res) => {
          const { receiver_notif } = res.data;
          const { title, description, campaign, _id } = res.data.data;
          handleSendNotif(receiver_notif, title, description, campaign, _id);
          handleSendtoTopic(title, description);
          console.log(res, "RESSS");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Your data cannot saved",
          });
        });
      // console.log(values, "VALUS");
    },
  });

  const handleSendtoTopic = (title, description) => {
    let campaignTitle = campaignDetailData?.title;
    const removeSpace = campaignTitle.replace(/\s+/g, "");
    const replaceTitle = removeSpace.replace(
      /[&\/\\#,+()$~%.'":*?!@<>{}]/g,
      "-"
    );
    const notifData = {
      topic: replaceTitle,
      notification: {
        title: "Campaign Update",
        body: title,
        imageUrl: "",
      },
      data: {},
    };
    // const notifData = {
    //   topic: "nama2",
    //   title: "judul",
    // };
    dispatch(postCampaignUpdateNotif(notifData))
      .then(unwrapResult)
      .then((res) => {
        console.log(res, "RES dari NOTIFF!!!");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Your data cannot saved",
        });
      });
    console.log(notifData, "notfdata");
  };

  const handleSendNotif = (
    receiver,
    title,
    desc,
    campaign_id,
    campaign_update_id
  ) => {
    const batch = db.batch();

    const data = {
      type: "Campaign Update", // campaignUpdate //notifeSuccss etc
      title: title,
      desc: desc,
      campaign_id: campaign_id,
      date: new Date(),
      isRead: false,
      campaign_update_id,
    };

    receiver.forEach((email) => {
      var docRef = messageRef.doc(uuidv4()); //automatically generate unique id
      const dataForSend = {
        email: email,
        ...data,
      };
      batch.set(docRef, dataForSend);
    });

    Swal.fire({
      // position: 'top-end',
      icon: "success",
      title: "Your data has been saved",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate(`/admin/campaign/detail/${id}`);
    });

    return batch.commit();
    // getNotif()s
  };

  const formField = [
    {
      id: "title",
      type: "text",
      label: "Article title",
      placeholder: "Insert article title",
    },
    {
      id: "description",
      type: "quill",
      label: "Article Content",
    },
  ];

  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    formik;

  console.log(campaignDetailData, "campdet");

  return (
    <Container fluid className="form-campaign">
      <pre>{JSON.stringify(values, null, 2)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre>
      <div className="top-row">
        <div className="title-container">
          <Link to={`/admin/campaign/detail/${id}`}>
            <ion-icon name="arrow-back-outline"></ion-icon>
          </Link>
          <span className="title">Create Campaign Update</span>
        </div>
        <BaseForm
          formField={formField}
          touched={touched}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          errors={errors}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Button className="mt-5" type="button" onClick={handleSubmit}>
          Create
        </Button>
      </div>
    </Container>
  );
}

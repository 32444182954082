import { createSlice } from "@reduxjs/toolkit";
import { deleteCreator, getCreator } from "./creator.api";

const initialState = {
    loading: false,
    listCreator: [],
};

export const creatorSlice = createSlice({
    name: "creator",
    initialState,
    reducers: {},
    extraReducers: {
        [getCreator.pending]: (state, action) => {
            state.loading = true;
        },
        [getCreator.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.listCreator = payload?.data;
        },
        [getCreator.rejected]: (state, action) => {
            state.loading = false;
        },
        [deleteCreator.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteCreator.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        [deleteCreator.rejected]: (state, action) => {
            state.loading = false;
        },
    },

});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = creatorSlice.actions;

export default creatorSlice.reducer;

import React from "react";
import LogoMobile from "../../../assets/BeriMakna_Green.png";

function Footer() {
  return (
    <div className="footer">
      <div className="container py-0">
        <div className="row">
          <div className="col-md-4 col-5">
            <div className="copyright text-right">contact@berimakna.com</div>
            <div className="copyright text-right">
              Copyright © 2022 Berimakna. All Rights Reserved.
            </div>
          </div>
          <div className="col-md-8 col-7">
            <p className="virtual text-right">
              <img src={LogoMobile} className="logo" alt="logo" />
            </p>
          </div>
        </div>
        {/* <div className="icons-mobile text-center">
          <ul className="p-0 m-0">
            <li>
              <a href="https://www.facebook.com/MindStores/" target="__blank">
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/mindstores?igshid=119ef4jvn6gk5"
                target="__blank"
              >
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCt7AdpqCAx0TRKmPxQE4UNQ"
                target="__blank"
              >
              </a>
            </li>
            <li>
              <a href="https://twitter.com/mindstores" target="__blank">
              </a>
            </li>
          </ul>
        </div> */}
        <div className="logo-mobile">
          <img src={LogoMobile} className="logo" alt="logo" />
        </div>
        <div className="copyright-mobile text-center">
          contact@berimakna.com
        </div>
        <div className="copyright-mobile text-center">
          <div>Copyright © 2022 Berimkana. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  );
}
export default Footer;

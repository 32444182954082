import React, { useEffect, useState } from "react";

import BackgroundHead from "../../../assets/Background.png";
import Logo from "../../../assets/BeriMakna_Logo.png";
import LogoMobile from "../../../assets/BeriMakna_Green.png";

import ScrollspyNav from "react-scrollspy-nav";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

function Navbar() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [nav, setNav] = useState(false);
  const [menu, setMenu] = useState(false);

  return (
    <>
      <div
        className={nav === true ? "head-mobile" : "head"}
        // style={
        //   isMobile === true
        //     ? {
        //         backgroundImage: `url(${BackgroundHead})`,
        //         backgroundSize: "250%",
        //         backgroundRepeat: "no-repeat",
        //         backgroundPositionX: "-230px",
        //       }
        //     : {
        //         backgroundImage: `url(${BackgroundHead})`,
        //         backgroundSize: "100%",
        //         backgroundRepeat: "no-repeat",
        //         backgroundPositionY: "-130px",
        //       }
        // }
      >
        <nav className="">
          <ScrollspyNav
            scrollTargetIds={["tes"]}
            activeNavClass="is-active"
            scrollDuration="1000"
            offset={100}
            // headerBackground="true"
          >
            <ul>
              <li>
                <a href="#">
                  <img src={Logo} className="logo" alt="logo" />
                </a>
              </li>
              <li className="navr">
                <a href="#tes" className="about-us-text">
                  About Us
                </a>
              </li>
              {/* ===Mobile View=== */}
              <li className="navr-mobile">
                <div className="language">
                  <a className="menu-cont" onClick={() => setNav(!nav)}>
                    {/* <img src={Menu} className="menu-icon" /> */}
                  </a>
                </div>
              </li>
            </ul>
          </ScrollspyNav>
        </nav>
        <div className="large-container">
          <div className="title-container">
            <h1 className="title-text">Bergerak,</h1>
            <h1 className="title-text">sekecil apapun itu.</h1>
            <h1 className="berimakna-text">Berimakna.</h1>
          </div>
        </div>
      </div>
      <div
        className="join-us"
        id="tes"
        style={
          isMobile === true
            ? {
                backgroundImage: `url(${BackgroundHead})`,
                backgroundSize: "250%",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "-230px",
              }
            : {
                backgroundImage: `url(${BackgroundHead})`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: "-130px",
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
              }
        }
      >
        <div className="title-container">
          <h1 className="title-text">Join #berimakna</h1>
        </div>
        <div className="description-container">
          <p className="description-text">
            <span className="bold">Berimakna</span> dibuat dengan tujuan membuka
            kesempatan bagi siapapun tanpa terbatas agar dapat berkontribusi
            langsung dalam memberikan makna bagi kehidupan mahluk hidup,
            lingkungan dan sosial agar dapat melangkah bersama mencapai
            kehidupan yang lebih baik.
          </p>
        </div>
      </div>
      <div className="about-us" id="tes">
        <div className="title-container">
          <h1 className="title-text">Coming Soon</h1>
        </div>
        <div className="description-container">
          <h1 className="description-text">
            Tanpa terbatas, siapapun dan dimanapun. <br /> Melangkah bersama
            memberi makna.
          </h1>
        </div>
      </div>
      <div className={nav === true ? "menu-view-active" : "menu-view"}>
        <nav>
          <ul>
            <li>
              <a href="#">
                <img src={Logo} className="logo" alt="logo" />
              </a>
            </li>
            <li className="close-icon">
              <a className="menu-cont" onClick={() => setNav(!nav)}>
                {/* <img src={Close} className='menu-icon'/> */}
              </a>
            </li>
          </ul>
        </nav>
        <div className="body">
          <a href="#media" className="menu-text" onClick={() => setNav(!nav)}>
            <p className="text-style">news</p>
          </a>
          <a href="#contact" className="menu-text" onClick={() => setNav(!nav)}>
            <p className="text-style">contact</p>
          </a>
        </div>
      </div>
    </>
  );
}

export default Navbar;

import React from "react"
import { Navigate } from "react-router-dom"


export const PrivateRoute = ({ children }) => {
  const authUser = localStorage.getItem('token')
  if (!authUser) return <Navigate to="/dashboard" />
  return children;
};

export const PublicRoute = ({ children }) => {
  const authUser = localStorage.getItem('token')
  if (authUser) return <Navigate to="/" />
  return children;
}

export const rupiahFormat = (value) =>
  new Intl.NumberFormat("id-ID", {
    style: 'currency', // add Rp
    currency: "IDR",
    maximumSignificantDigits: 15
  }).format(value);


import { configureStore } from "@reduxjs/toolkit";
import articleReducer from "../pages/article/article.reducer";
import authReducer from "../pages/authentication/auth.reducer";
import campaignReducer from "../pages/campaign/campaign.reducer";
import creatorReducer from "../pages/creator/creator.reducer";
import homeReducer from "../pages/home/home.reducer";
import sharedReducer from "../pages/shared/shared.reducer";
import userReducer from "../pages/user/user.reducer";
import transactionReducer from "../pages/transaction/transaction.reducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
    user: userReducer,
    creator: creatorReducer,
    campaign: campaignReducer,
    article: articleReducer,
    shared: sharedReducer,
    transaction: transactionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

import { createSlice } from "@reduxjs/toolkit";
import {
  deleteArticle,
  getArticle,
  postArticle,
  getArticleDetail,
  patchArticle,
} from "./article.api";

const initialState = {
  loading: false,
  listArticle: [],
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {},
  extraReducers: {
    [getArticle.pending]: (state, action) => {
      state.loading = true;
    },
    [getArticle.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listArticle = payload?.data;
    },
    [getArticle.rejected]: (state, action) => {
      state.loading = false;
    },
    [postArticle.pending]: (state, action) => {
      state.loading = true;
    },
    [postArticle.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postArticle.rejected]: (state, action) => {
      state.loading = false;
    },
    //edit
    [patchArticle.pending]: (state, action) => {
      state.loading = true;
    },
    [patchArticle.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [patchArticle.rejected]: (state, action) => {
      state.loading = false;
    },
    //delete
    [deleteArticle.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteArticle.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteArticle.rejected]: (state, action) => {
      state.loading = false;
    },
    [getArticleDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getArticleDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getArticleDetail.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = articleSlice.actions;

export default articleSlice.reducer;

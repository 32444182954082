import { createSlice } from "@reduxjs/toolkit";
import { getUser, getDataDashboard } from "./home.api";

const initialState = {
  value: 0,
  loading: false,
  userList: [],
  dashBoardData: [],
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: {
    [getUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      //mean action.payload
      state.userList = payload;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDataDashboard.pending]: (state, action) => {
      state.loading = true;
    },
    [getDataDashboard.fulfilled]: (state, { payload }) => {
      state.loading = false;
      //mean action.payload
      state.dashBoardData = payload;
    },
    [getDataDashboard.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = homeSlice.actions;

export default homeSlice.reducer;

import Authentication from "../pages/authentication/AuthenticationPage";
import Home from "../pages/home/Home";
import Campaign from "../pages/campaign/Campaign";
import User from "../pages/user/User";
import TransactionHistory from "../pages/transaction/TransactionHistory";
import Article from "../pages/article/Article";
import CampaignCreator from "../pages/creator/CampaignCreator";
import CampaignDetails from "../pages/campaign/components/CampaignDetails";
import FormCampaign from "../pages/campaign/components/FormCampaign";
import EditCampaign from "../pages/campaign/components/EditFormCampaign";
import FormCreator from "../pages/creator/components/FormCreator";
import EditCreator from "../pages/creator/components/EditFormCreator";
import FormArticle from "../pages/article/components/FormArticle";
import EditArticle from "../pages/article/components/EditFormArticle";
import CampaignUpdateForm from "../pages/campaign/components/CampaignUpdateForm";
import Play from "../pages/playground/Play";
import LandingPage from "../pages/landingpage/LandingPage";
import VerificationSuccess from "../pages/verification/VerificationSuccess";
import VerificationFail from "../pages/verification/VerificationFail";
import ForgotPassword from "../pages/forgotpass/ForgotPassword";
import ResetPasswordSuccess from "../pages/forgotpass/components/ResetPasswordSuccess";
import ResetPasswordFailed from "../pages/forgotpass/components/ResetPasswordFailed";

const routes = [
  {
    path: "/",
    element: <LandingPage />,
    isProtected: false,
  },
  {
    path: "/verificationsucceed",
    element: <VerificationSuccess />,
    isProtected: false,
  },
  {
    path: "/verificationfailed",
    element: <VerificationFail />,
    isProtected: false,
  },
  {
    path: "/reset-password/:token",
    element: <ForgotPassword />,
    isProtected: false,
  },
  {
    path: "/resetpasswordsuccess",
    element: <ResetPasswordSuccess />,
    isProtected: false,
  },
  {
    path: "/resetpasswordfailed",
    element: <ResetPasswordFailed />,
    isProtected: false,
  },
  {
    path: "/admin",
    element: <Authentication />,
    isProtected: false,
  },
  {
    path: "/admin/dashboard",
    element: <Home />,
    isProtected: true,
  },
  {
    path: "/admin/campaign",
    element: <Campaign />,
    isProtected: true,
  },
  {
    path: "/admin/user",
    element: <User />,
    isProtected: true,
  },
  {
    path: "admin/transactionhistory",
    element: <TransactionHistory />,
    isProtected: true,
  },
  {
    path: "/admin/article",
    element: <Article />,
    isProtected: true,
  },
  {
    path: "/admin/campaigncreator",
    element: <CampaignCreator />,
    isProtected: true,
  },
  {
    path: "/admin/campaign/detail/:id",
    element: <CampaignDetails />,
    isProtected: true,
  },
  {
    path: "/admin/campaign/form",
    element: <FormCampaign />,
    isProtected: true,
  },
  {
    path: "/admin/campaign/form/:id",
    element: <EditCampaign />,
    isProtected: true,
  },
  {
    path: "/admin/campaign/detail/:id/campaignupdate",
    element: <CampaignUpdateForm />,
    isProtected: true,
  },
  {
    path: "/admin/campaigncreator/form",
    element: <FormCreator />,
    isProtected: true,
  },
  {
    path: "/admin/campaigncreator/:id",
    element: <EditCreator />,
    isProtected: true,
  },
  {
    path: "/admin/article/form",
    element: <FormArticle />,
    isProtected: true,
  },
  {
    path: "/admin/article/:id",
    element: <EditArticle />,
    isProtected: true,
  },
  {
    path: "/play",
    element: <Play />,
    isProtected: true,
  },
];

export default routes;
